/* eslint-disable */
import { mapGetters } from 'vuex'
import portfolio from '../../../constant/portfolio'
export default {
  name: 'talent',
  data: () => ({
    ourService: [
      {
        title: 'High Quality Expertise',
        description: 'We will help you to stay ahead of the competition and attract the best talent in their industry.'
      },
      {
        title: 'Effective & Efficient Employee Process',
        description: 'Optimize recruitment process, reduce costs, and achieve better business outcomes.'
      },
      {
        title: 'Wide Range of Skill & Seniority Level',
        description: 'We provide a wide range of solutions that cater to all skill levels and seniority ranks, to help you build a strong team that drives business growth.'
      }
    ],
    partners: ['astrapay', 'gotoko', 'btpns', 'bri', 'telkomsat', 'best', 'jet-brains', 'smartfren'],
    expertise: [
      'Mobile Developer',
      'Web Developer',
      'Quality Assurance',
      'System Administrator',
      'Database Administrator',
      'Database Engineer',
      'Product Manager',
      'Product Owner',
      'Data Analyst',
      'Risk Analyst'
    ],
    carouselOptions: {
      type: 'slide',
      autoplay: true,
      arrows: false,
      perPage: 1,
      speed: 1000,
      pagination: true,
      interval: 3000,
      gap: '1rem',
      lazyLoad: 'nearby',
      breakpoints: {
        767: {
          perPage: 1,
          type: 'loop'
        }
      }
    },
    currentPortofolioPage: 1,
    pageSize: 6,
    isVisibleDetail: false,
    selectedPortfolio: 0
  }),
  components: {
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    Student: () => import(/* webpackChunkName: "student" */ '@/components/icons/Student'),
    School: () => import(/* webpackChunkName: "school" */ '@/components/icons/School'),
    CodeSlash: () => import(/* webpackChunkName: "code-slash" */ '@/components/icons/CodeSlash'),
    Rocket: () => import(/* webpackChunkName: "rocket" */ '@/components/icons/Rocket'),
    People: () => import(/* webpackChunkName: "people" */ '@/components/icons/People'),
    ImageItem: () => import(/* webpackChunkName: "image" */ '@/components/image/ImageItem'),
    ProductCard: () => import(/* webpackChunkName: "image" */ '@/components/card/ProductCard'),
    TestimonyCard: () => import(/* webpackChunkName: "image" */ '@/components/card/TestimonyCard'),
    Carousel: () => import(/* webpackChunkName: "carousel" */ '@/components/carousel/Carousel'),
    Modal: () => import(/* webpackChunkName: "modal" */ '@/components/modal/Modal')
  },

  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },
  computed: {
    ...mapGetters('common', ['isMobile']),
    videoLink() {
      return 'https://www.youtube.com/embed/fuXrwwGhUes'
    },
    partnersCol1() {
      return this.isMobile ? this.partners.slice(0, 1) : this.partners
    },
    partnersCol2() {
      return this.partners.slice(1, 3)
    },
    partnersCol3() {
      return this.partners.slice(3, 5)
    },
    talentPortfolio() {
      return portfolio.filter((item) => item.type === 'talent')
    },
    portfolioList() {
      return this.talentPortfolio.slice(0, this.currentPortofolioPage * this.pageSize)
    },
    totalPortfolio() {
      return this.talentPortfolio.length
    }
  },
  methods: {
    toContactUs() {
      this.$router.push({ name: 'Contact Us' })
    },
    toPartner() {
      this.$router.push('/register-alliance')
    },
    toSolution(item) {
      this.$router.push(item)
    },
    toggleVisibleDetail(value) {
      this.selectedPortfolio = value || {}
      this.isVisibleDetail = !this.isVisibleDetail
    },
    loadMore() {
      if (this.portfolioList.length !== this.totalPortfolio) {
        this.currentPortofolioPage++
      }
    }
  }
}
